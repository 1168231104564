export default [
    {
        key:"petty_cash",
        label:"Caja Chica",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"responsible",
        label:"Responsable",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"balance",
        label:"Saldo",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"petty_cash_destination",
        label:"Caja destino",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"responsible_destination",
        label:"Responsable",
        thClass:"text-center",
        visible:true   
    },
    {
        key:"created_at",
        label:"Fecha",
        thClass:"text-center",
        visible:true   
    },
    // {
    //     key:"actions",
    //     label:"Acciones",
    //     thClass:"text-center",
    //     visible:true   
    // },
]