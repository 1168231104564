<template>
	<b-modal
		ref="modal-cash-balances"
		@hidden="$emit('close')"
		:title="getTitle"
		cancel-title="Cancelar"
		scrollable
		size="xmd"
	>
		<div>
			<b-card no-body class="mb-1">
				<div class="m-2">
					<!-- Paginator -->
					<div class="mx-2 mb-2">
						<b-row>
							<b-col
								cols="12"
								sm="6"
								class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
							>
								<span class="text-muted">
									Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
								</span>
							</b-col>
							<!-- Pagination -->
							<b-col
								cols="12"
								sm="6"
								class="d-flex align-items-center justify-content-center justify-content-sm-end"
							>
								<b-pagination
									v-model="current_page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									class="mb-0 mt-1 mt-sm-0"
									prev-class="prev-item"
									next-class="next-item"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</b-col>
						</b-row>
					</div>

					<b-row>
						<b-col cols="12" xl="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
							<label>Mostrar</label>
							<v-select
								v-model="perpage"
								:options="perPageOptions"
								:clearable="false"
								class="per-page-selector d-inline-block mx-50"
							/>
							<label class="mr-2">registros</label>
							<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" />
						</b-col>

						<!-- Search -->
						<b-col cols="12" xl="8" class="mt-1">
							<div class="container-btns-page-specimens">
								<div class="d-flex">
									<b-form-input
										v-model="search_input"
										class="d-inline-block mr-1"
										placeholder="Codigo o responsable..."
										debounce="500"
										@keyup.enter="resetSearch"
									/>
									
								</div>
							</div>
						</b-col>
					</b-row>
				</div>

				<div class="table-responsive">
					<b-table
						:items="myProvider"
						:fields="showFields"
						sticky-header="75vh"
						primary-key="id"
						:current-page="current_page"
						:per-page="perpage"
						responsive
						small
						show-empty
						ref="table-petty-cash"
					>
						<template #table-busy>
							<div class="text-center text-primary my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Cargando...</strong>
							</div>
						</template>

						<template #cell(petty_cash)="data">
							<div class="d-flex justify-content-center">
								<span>{{ data.item.petty_cash }}</span>
							</div>
						</template>
						<template #cell(responsible)="data">
							<div class="d-flex justify-content-center">
								<span>{{ data.item.responsible }}</span>
							</div>
						</template>
						<template #cell(balance)="data">
							<div class="d-flex justify-content-center">
								<span>{{ data.item.balance | currency }}</span>
							</div>
						</template>
						<template #cell(petty_cash_destination)="data">
							<div class="d-flex justify-content-center">
								<span v-if="data.item.petty_cash_destination_id != null">
									{{ data.item.petty_cash_destination }}
								</span>
								<b-badge v-else variant="danger">No asignado</b-badge>
							</div>
						</template>
						<template #cell(responsible_destination)="data">
							<div class="d-flex justify-content-center">
								<span v-if="data.item.petty_cash_destination_id != null">
									{{ data.item.responsible_destination }}
								</span>
								<b-badge v-else variant="danger">No asignado</b-badge>
							</div>
						</template>
						<template #cell(created_at)="data">
							<div class="d-flex align-items-center justify-content-center">
								<feather-icon icon="CalendarIcon" class="text-success" />
								<span style="margin-left: 5px; font-size: 13px">
									{{ data.item.created_at | myGlobalDayWithHour }}
								</span>
							</div>
						</template>
					</b-table>
				</div>
			</b-card>
		</div>
		<template #modal-fotter>
			<b-button @click="$emit('close')">Cancelar</b-button>
		</template>
	</b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive"
import modalMixin from "@/mixins/modal.js"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"
import Fields from "@/views/amg/management/views/petty-cash/data/fields-cash-balances.data.js"

export default {
	props: {},
	directives: { Ripple },
	components: {},
	mixins: [modalMixin],
	data() {
		return {
			balances: [],
			fields: Fields,
			start_page: 0,
			end_page: 0,
			total_data: 0,
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: 0,
			perPageOptions: [10, 25, 50, 100],
			search_input: "",
			filterController: false,
		}
	},
	computed: {
		getTitle() {
			return "Detalle de saldos"
		},
		showFields() {
			return this.fields.filter((f) => f.visible)
		},
	},
	async mounted() {
		this.toggleModal("modal-cash-balances")
	},
	async created() {},
	methods: {
		async myProvider(ctx) {
			this.isPreloading()
			try {
				const params = {
					search: this.search_input,
					responsible_id: null,
					page: ctx.currentPage,
					perpage: ctx.perPage,
				}

				const { data } = await PettyCashService.getCashBalances(params)
				this.start_page = data.from
				this.to_page = data.to
				this.total_data = data.total
				this.current_page = data.current_page
				return data.data
			} catch (error) {
				console.log(error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		async deleteTransaction(transac) {
			const confirm = await this.showConfirmSwal({
				text: "Eliminar transaccion",
			})
			if (!confirm.isConfirmed) return
			this.isPreloading()
			try {
				const deleted = await PettyCashService.deleteTransaction(transac.id)
				this.showSuccessToast("Transaccion eliminado correctamente")
				this.getTracking()
				this.$emit("refresh")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		resetSearch() {
			this.$refs["table-petty-cash"].refresh()
		},
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>
